import React, { useEffect, useRef, useState } from 'react';
import * as THREE from 'three';
import { MTLLoader } from 'three/addons/loaders/MTLLoader';
import { OBJLoader } from 'three/addons/loaders/OBJLoader';

const Splash = () => {
    const ref = useRef(null);

    const initScene = () => {
        const scene = new THREE.Scene();
        const camera = new THREE.PerspectiveCamera(
            75,
            window.innerWidth / window.innerHeight,
            0.1,
            1000,
        );

        const renderer = new THREE.WebGLRenderer();
        renderer.setSize(window.innerWidth, window.innerHeight);
        if (!ref.current.children.length) {
            ref.current.appendChild(renderer.domElement);
        }
        let toilet;
        let pivot;
        const mtlLoader = new MTLLoader();
        mtlLoader.load('assets/materials/toilet.mtl', function (materials) {
            materials.preload();
            const objLoader = new OBJLoader();
            objLoader.setMaterials(materials);
            objLoader.load('assets/models/toilet.obj', function (object) {
                var box = new THREE.Box3().setFromObject(object);
                var center = new THREE.Vector3();
                box.getCenter(center);
                object.position.sub(center);
                toilet = object;

                pivot = new THREE.Group();
                scene.add(pivot);
                pivot.add(toilet);
            });
        });
        const ambientLight = new THREE.AmbientLight(0xcccccc, 0.8);
        scene.add(ambientLight);

        const pointLight = new THREE.PointLight(0xffffff, 0.5, 50);
        pointLight.position.z += 20;
        scene.add(pointLight);
        camera.position.z = 50;

        const animate = function () {
            requestAnimationFrame(animate);
            if (pivot) {
                pivot.rotation.x += 0.01;
                pivot.rotation.y += 0.01;
            }
            renderer.render(scene, camera);
        };
        animate();
        const onWindowResize = () => {
            camera.aspect = window.innerWidth / window.innerHeight;
            camera.updateProjectionMatrix();

            renderer.setSize(window.innerWidth, window.innerHeight);
        };
        window.addEventListener('resize', onWindowResize, false);
    };

    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            initScene();
        }

        return () => {
            isMounted = false;
        };
    }, []);

    return (
        <>
            <div
                style={{
                    position: 'absolute',
                    top: '15%',
                    left: 0,
                    right: 0,
                    fontSize: 32,
                    color: 'white',
                }}>
                Flushed is in development!
            </div>
            <div ref={ref}></div>
        </>
    );
};

export default Splash;
