import './App.css';
import Splash from './Components/Splash';

function App() {
    return (
        <div className="App">
            <Splash />
        </div>
    );
}

export default App;
